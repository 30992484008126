import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Container, Row, Col, Image } from "react-bootstrap"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

// import { useGlobalState } from "../../../global/global"
// import { getLanguageFile } from "../../../global/langauge.helper"
import { TextFormater } from "../../../global/textFormater"

import ETECUBE_INCIDENT_1 from "../../../../assets/images/etecube/Incident_1.png"
import ETECUBE_INCIDENT_2 from "../../../../assets/images/etecube/Incident_2.png"
import ETECUBE_ACTION_1 from "../../../../assets/images/etecube/Action_1.png"
import ETECUBE_ACTION_2 from "../../../../assets/images/etecube/Action_2.png"
import ETECUBE_EMM_1 from "../../../../assets/images/etecube/EMM_1.png"
import ETECUBE_MIL_1 from "../../../../assets/images/etecube/MIL_1.png"
import ETECUBE_TOREPORT_1 from "../../../../assets/images/etecube/TOReport_1.png"
import ETECUBE_TRAININGOBJECTIVE_1 from "../../../../assets/images/etecube/TrainingObjective_1.png"
import ETECUBE_INCIDENTCHART_1 from "../../../../assets/images/etecube/IncidentChart_1.png"
import ETECUBE_FLOW_1 from "../../../../assets/images/etecube/Flow_1.png"
import ETECUBE_TrainingObjectiveAndIncidentChart_1 from "../../../../assets/images/etecube/TrainingObjectiveAndIncidentChart_1.png"

import "./styles.scss"

const ETECubePage = () => {
  // const [state] = useGlobalState()

  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>etecube®</title>
        </Helmet>
      </div>
      <Header />
      {/* <Container className="introduction-container ">
        <Row>
          <Col className="introduction-text-col col-lg-4 order-12 order-lg-1">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3 className="introduction-text-heading">ETECube</h3>
              <h6 className="copyright-text">®</h6>
            </div>
            <h5 className="introduction-text">
              {getLanguageFile(state.language).Nginr.nginrMainDescription}
            </h5>
          </Col>
        </Row>
      </Container> */}
      <Container className="features-container ">
        <p className="features-text">
          <h4>
            <b>
              <center>
                {TextFormater(
                  "etecube<0>®</0>: System for Education Training Exercise Experimentation Evaluation"
                )}
              </center>
            </b>
          </h4>
          <br />
          <p>
            {TextFormater(
              "etecube<0>®</0> is a toolset for planning and managing education training exercise experimentation and evaluation (etecube<0>®</0>) activities. It consists of the following modules:"
            )}
            <br />
          </p>
          <p>
            <ul>
              <li>
                <b style={{ fontSize: 14 }}>
                  Objective Management Module (OMM)
                </b>{" "}
                is for analyzing scenario and main incident list conditions, key
                processes and targeted key functional areas for each training
                and experimentation objective.
              </li>
              <li>
                <b style={{ fontSize: 14 }}>
                  Setting/Scenario Management Module (SMM)
                </b>{" "}
                provides a collaborative environment for developing and managing
                settings and scenarios.
              </li>
              <li>
                <b style={{ fontSize: 14 }}>Flow Management Module (FMM)</b> is
                used by main incident list (MIL) developers for creating MIL and
                managing it dynamically to steer an exercise process towards the
                exercise and training objectives.
              </li>
              <li>
                <b style={{ fontSize: 14 }}>
                  Experiment Management Module (EMM)
                </b>{" "}
                is for designing experiments according to the experimentation
                objectives, collecting data during experimentations and
                reporting results.
              </li>
              <li>
                <b style={{ fontSize: 14 }}>
                  Lessons Identified and Lessons Learned Module (LILL)
                </b>{" "}
                records observations and appropriations, and supports LILL
                analysts to verify, manage and validate lessons.
              </li>
              <li>
                <b style={{ fontSize: 14 }}>Real Life Support Module (RLSM)</b>{" "}
                is for managing configuration change requests and trouble
                tickets.
              </li>
            </ul>
          </p>
          <p>
            {TextFormater(
              "etecube<0>®</0> is an integrated part of hybrid multidomain operations tactics and strategy simulator (hymots<0>®</0>). Please visit "
            )}

            <Link to={"https://www.hymots.info"} target="_blank">
              www.hymots.info
            </Link>

            {TextFormater(
              " for further information about hymots<0>®</0>. Incidents in etecube<0>®</0> can be related to the agents and commands in hymots<0>®</0>, hence a real time visualization of incidents and an automatic feed back to the objective management module are enabled. Similarly, a simulation command in hymots<0>®</0> can be related to an incident in nginr<0>®</0>. Alternatively, customers can procure and use etecube<0>®</0> also independently from hymots<0>®</0>."
            )}
          </p>

          <Row>
            <Col className="features-img-col col-lg-3 col-12 ">
              <Image src={ETECUBE_INCIDENT_1} className="features_img"></Image>
            </Col>
            <Col className="features-img-col col-lg-3 col-12 ">
              <Image src={ETECUBE_INCIDENT_2} className="features_img"></Image>
            </Col>
            <Col className="features-img-col col-lg-3 col-12 ">
              <Image src={ETECUBE_ACTION_1} className="features_img"></Image>
            </Col>
            <Col className="features-img-col col-lg-3 col-12 ">
              <Image src={ETECUBE_ACTION_2} className="features_img"></Image>
            </Col>
          </Row>

          <h5 style={{ color: "black" }}>
            {" "}
            <b>{TextFormater("Objective Management Module (OMM)")}</b>
          </h5>
          <p>
            OMM is for analyzing the scenario and main incident list (MIL)
            conditions, key processes and targeted key functional areas for each
            training and experimentation objective. OMM provides the
            collaborative environment for the planners from officer scheduling
            exercise (OSE), officer directing exercise (ODE) and officer
            conducting exercise (OCE) to determine training objectives and
            scenario and MIL conditions to provide the required opportunities
            for practicing with the training objectives (TO). The training
            objectives are determined according to the strengths and weaknesses
            of training audience. Therefore, the lead person in this process is
            the OCE officer prime responsibility (OPR). Training/experimentation
            objectives should also be related to the exercise objectives given
            by the OSE. The training objective selection process is a structured
            process.
          </p>
          <Row>
            <Col className="features-img-col">
              <Image
                src={ETECUBE_TRAININGOBJECTIVE_1}
                className="features_img_center"
              ></Image>
            </Col>
          </Row>
          <p>
            The units/headquarters aim at developing the required competencies
            for the tasks related to their mission. OCE selects a subset of
            these competencies as the training objectives after analyzing the
            strengths and weaknesses of the unit/headquarters and the available
            resources including time. Moreover, these competencies require
            certain collective skills, individual skills and knowledge.
            Collective skills become the training objectives for the collective
            training events, such as battle staff training, during an exercise
            process. Similarly, individual skills are the training objectives
            for the individual training, and individual knowledge is the
            learning objectives for the education during an exercise process.
            The education and training during an exercise should flow such that
            their outcomes (i.e., learning and training outcomes) covers the
            training/learning objectives. Note that a training objective is not
            defined only as a title and description but includes details like
            the parts of the training audience and key processes aimed with each
            objective.
          </p>
          <Row>
            <Col className="features-img-col">
              <Image
                src={ETECUBE_TOREPORT_1}
                className="features_img_center"
              ></Image>
            </Col>
          </Row>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("Setting/Scenario Management Module (DMM)")}</b>
          </h5>
          <p>
            {TextFormater(
              "A scenario sets the starting point and an exercise flow that provide the conditions over which the audience of an exercise can practice with their training objectives. In other words, a scenario must contain all the data, information and knowledge "
            )}
          </p>
          <p>
            <ul>
              <li>
                to immerse the training audience into a rich and realistic
                situation,
              </li>
              <li>
                and to provide the conditions required to meet the training
                objectives.
              </li>
            </ul>
          </p>
          <p>
            Therefore, each exercise has its unique scenario, which can hardly
            be reused. Scenarios are designed from a setting which is the
            description of and the material related to geostrategic situation
            and theater of operations. Settings and scenarios can be developed
            and managed better when an effective collaborative environment is
            available. SMM provides such an environment with an extensive set of
            software tools and data.
          </p>
          <p>
            {TextFormater(
              "Exercise scenarios are large knowledge base which include many components, such as MIL, maps, geo data, weather data, order of battle for all sides, in other words the data required to create a virtual theater. Therefore, not only FMM but also SMM is seamlessly integrated with hymots<0>®</0>, which can be used for building a significant portion of exercise scenarios and retrieves large datasets from SMM."
            )}
          </p>
          <br />
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("Flow Management Module (FMM)")}</b>
          </h5>
          <p>
            {TextFormater(
              "During execution of an exercise, training audience should be given the opportunities for practicing with their training objectives. This can be achieved by controlling the flow of the exercise such that those conditions are created. Moreover, exercise control should be informed and ready when those conditions are created to analyze and assess the training audience performance in meeting their training objectives. The exercise flow needs to be dynamically managed based on the results of this assessment in order to steer the exercise towards the training objectives. This is managed by designing main even main incident lists (MEL/MIL), connecting MEL/MIL with the training objectives, connecting the observation tasks and training outcome analysis with MEL/MIL and synchronizing the virtual theater (i.e., simulation) with the MEL/MIL. Please note that a constructive simulation system typically maintains the virtual theater. FMM provides the collaborative working environment for building a MEL/MIL, managing it according to training outcomes, and synchronizing it with the simulation systems.  "
            )}{" "}
          </p>
          <p>
            FMM analyzes the observations entered by the observers and trainers
            and depicts autonomously how well the training objectives are met.
            Observers, trainers and response cells enter their observations to
            the system according to the observation tasking made through FMM.
            For this, at least one incident is designed for each training
            objective. An incident is something that the training audience need
            to act on when it happens. These responses to the incidents by the
            training audience are the training outcomes of the incident and
            directly related to the training objectives. They may stay inside
            training audience (planning, coordination, reports, orders, etc.) or
            send by the training audience to higher level (HICON) or lower level
            (LOCON) in the form of orders, reports, request for
            clarification/information/coordination. The training audience are
            made aware of the incident either by injections (i.e., reports and
            orders by HICON, LOCON, white cell, media simulation) or by changing
            the operational picture through simulation (i.e., actions).
          </p>
          <p>
            FMM includes tools to monitor and manage the content and status of
            every construct in MEL/MIL, such as injections, actions and
            outcomes.
          </p>
          <Row>
            <Col className="features-img-col">
              <Image
                src={ETECUBE_MIL_1}
                className="features_img_center"
              ></Image>
            </Col>
          </Row>
          <p>
            FMM provides exercise control staff (EXCON) with a common operating
            picture (COP) for EXCON. Although EXCON COP is dynamic and can be
            changed, the default configuration illustrates the following:
            <ul>
              <li>injection/action status list</li>
              <li>training objective status </li>
              <li>incident structure</li>
              <li>
                common operational picture as perceived by the selected training
                audience
              </li>
              <li>
                common operational picture as the ground truth with the details
                of the selected incidents
              </li>
              <li>EXCON Battle Rhythm.</li>
            </ul>
            <p>
              EXCON COP can be configured dynamically, and various filters can
              be applied in each screen.
            </p>
          </p>
          <Row>
            <Col className="features-img-col col-lg-4 col-12 ">
              <Image
                src={ETECUBE_INCIDENTCHART_1}
                className="features_img"
              ></Image>
            </Col>
            <Col className="features-img-col col-lg-4 col-12 ">
              <Image src={ETECUBE_FLOW_1} className="features_img"></Image>
            </Col>
            <Col className="features-img-col col-lg-4 col-12 ">
              <Image
                src={ETECUBE_TrainingObjectiveAndIncidentChart_1}
                className="features_img"
              ></Image>
            </Col>
          </Row>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("Experiment Management Module (EMM)")}</b>
          </h5>
          <p>
            {TextFormater(
              "EMM is for designing experiments according to experimentation objectives and steering an experiment towards its objectives. EMM is a variation of FMM and OMM adapted for military experimentation. It provides a collaborative environment to choose dependent (measures) and independent (factoring) parameters according to the experiment objectives, develop hypothesis, design experimentation configurations (i.e., value sets for the independent parameters), to collect and analyze experiment results."
            )}{" "}
          </p>
          <p>
            EMM is also used for designing experiments and collecting data from
            experiments. When dependent (measures) and independent (factoring)
            parameters are picked according to the experimentation objectives,
            the experimentation configurations (i.e., value sets for the
            independent parameters) are determined with the help of EMM which
            also collects and analyzes experiment results. It supports all kinds
            of military experimentation including discovery, hypothesis,
            validation and demonstration experiments.
          </p>
          <Row>
            <Col className="features-img-col">
              <Image
                src={ETECUBE_EMM_1}
                className="features_img_center"
              ></Image>
            </Col>
          </Row>
          <br />
          <h5 style={{ color: "black" }}>
            <b>
              {TextFormater(
                "Lessons Identified and Lessons Learned Module (LILL)"
              )}
            </b>
          </h5>
          <p>
            LILL records observations and appropriations, and support LILL
            analysts to verify, manage and validate lessons. LILL is designed
            for collecting and analyzing the lessons from the actions of TA and
            EXCON. Any exercise/experiment participant can make an observation
            which are grouped according to a set of predefined categories. The
            appropriators of the categories write comments on the observations.
            An analyst analyzes the observations and the comments. When an
            observation is verified by an analyst after this process, it becomes
            an identified lesson.
          </p>
          <p>
            All the actions taken on the lessons and their results are recorded
            in the system. Once an analyst validates that the actions to correct
            a lesson meets the objective, the status of the lesson is changed as
            a lesson learned. Lessons learned are not removed from the database
            and reminded to the related staff members and teams periodically.
          </p>
          <p>
            The lessons identified and lessons learned can be published to the
            other entities when the analyst recommends, and the owner of the
            lesson approves.
          </p>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("Real Life Support Module (RLSM)")}</b>
          </h5>
          <p>
            {TextFormater(
              "RLSM is for managing the configuration change requests and trouble tickets. RLSM is used for planning and managing all sort of real life support issues including transportation, security, accommodation, catering, in-processing and out-processing. Major functions of RLSM include but not limited to the following: "
            )}{" "}
          </p>
          <p>
            <ul>
              <li> CIS configuration change request management </li>
              <li> CIS trouble ticket management</li>
              <li> RLS service request management</li>
              <li> RLS trouble ticket management</li>
              <li> Cyber security incident reporting and first response</li>
              <li> Physical security incident reporting and first response</li>
              <li> Personal administration form (PAF) management</li>
            </ul>
          </p>
        </p>
        {/* <p className="features-text">
          {getLanguageFile("en").ETECube.etecubeBooklet}
        </p> */}
      </Container>
      <Footer />
    </Container>
  )
}

export default ETECubePage
